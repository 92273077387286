import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';
import { DEBUG, ERROR } from './utils';

const APP_NAME = "PS_ADMIN";

const { REACT_APP_FB_APIKEY, REACT_APP_FB_PROJECT_ID, REACT_APP_FB_SENDER_ID } = process.env;
const config = {
  apiKey: REACT_APP_FB_APIKEY,
  authDomain: `${REACT_APP_FB_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${REACT_APP_FB_PROJECT_ID}.firebaseio.com`,
  projectId: `${REACT_APP_FB_PROJECT_ID}`,
  storageBucket: `${REACT_APP_FB_PROJECT_ID}.appspot.com`,
  messagingSenderId: REACT_APP_FB_SENDER_ID
};

let firebaseApp;

try {
  DEBUG('Firebase Project', APP_NAME, config.projectId);
  if (!firebase.apps.length) {
    firebaseApp = firebase.initializeApp(config);
  } else {
    firebaseApp = firebase.app();
  }
} catch (err) {
  ERROR(err);
}

const uploadImgToStorage = (imgPath, imgData) => {
  return firebaseApp.storage().ref().child(imgPath).putString(imgData, 'data_url');
};

const uploadFileToStorage = (filePath, file) => {
  return firebaseApp.storage().ref().child(filePath).put(file);
};

export const uploadPhoto = (imgPath, imgData) => {
  if (imgData && imgData.includes('http')) {
    return Promise.resolve({ url: imgData });
  } else if (!imgPath || !imgData) {
    return Promise.resolve({ url: undefined });
  } else {
    return uploadImgToStorage(imgPath, imgData)
      .then(async res => {
        let downloadUrl = await res.ref.getDownloadURL();
        return { url: downloadUrl };
      })
      .catch(err => {
        return Promise.reject({ url: undefined });
      });
  }
};

export const uploadFile = (filePath, file) => {
  return new Promise((resolve, reject) => {
    if (!filePath || !file) {
      reject();
    } else {
      return uploadFileToStorage(filePath, file)
        .then(async res => {
          let downloadUrl = await res.ref.getDownloadURL();
          resolve({ url: downloadUrl });
        })
        .catch(err => {
          reject(err);
        });
    }
  });
};

export const signinWithCustomToken = (token) => {
  return firebaseApp.auth().signInWithCustomToken(token);
};

export const deletePhoto = (imgPath) => {
  return firebase.app(APP_NAME).storage().refFromURL(imgPath).delete();
}


export default config;
export const FB_Auth = firebaseApp.auth();
export const FB_Storage = firebaseApp.storage();
export const FB_Functions = firebaseApp.functions();
export const FB_Firestore = firebaseApp.firestore();
