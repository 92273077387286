import React, { useEffect } from "react";

export default function ScrollToTop() {
  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById("scroll-to-me");
      element.scrollIntoView({
          behavior: 'smooth'
      }, 500)
    }, 30);
  }, []);

  return <div id="scroll-to-me" style={{ position: 'absolute', top: 0, left: 0, right: 0 }}></div>;
}